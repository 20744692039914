import React, { useState, useEffect } from "react";

const Slideshow = ({ images, interval = 3000 }) => {
  return (
    <>
      <div className="home">
        <div class="home-content">
          <div class="welcome-banner">
            <p style={{
              color:"blue"
            }}>BEST MARKETING SERVICE</p>
            <h1>Talent Nest Technology Makes The Easiest Solution For You</h1>
            <p>
              Talent Nest is an innovative and progressive IT services provider, dedicated to offering exceptional and cutting-edge solutions tailored specifically to address the unique needs and challenges of your business.
            </p>
            <br />
            <br />
            <a href="tel:+916300483327">Make an Appointment</a>
          </div>
        </div>
        <div className="home-image">
        <img src="./images/home.png" alt="" />
      </div>
      </div>
     
    </>
  );
};

export default Slideshow;
