import React from "react";
import MyService from "./MyService";
import Serve from "./Serve";
import ContactCard from "../cards/ContactCard";
export default function OutService() {
  return (
    <>
      <div className="title-container">
        <h1>Out Sourcing</h1>
      </div>
      <MyService title="Out Sourcing Service" image="./images/services/out2.png"
      desc="Unlock your business potential with our comprehensive outsourcing solutions, allowing you to concentrate on core competencies and drive growth. Enjoy benefits such as cost efficiency through a cost-effective offshore workforce, effortless scalability, and access to skilled professionals. Enhance your focus on core strategies and innovation while mitigating risks associated with hiring, training, and managing an in-house team. Free up valuable time and resources, enabling you to steer your organization towards success. Discover the advantages of outsourcing with us and witness a significant boost in productivity and profitability. Let us handle the workload while you concentrate on achieving your business goals."/>
      <Serve image="./images/services/out1.png" />
      <ContactCard/>
    </>
  );
}
