import React, { useEffect } from "react";
import AOS from 'aos';
import "aos/dist/aos.css";
import './about.css';
import AboutCard from './cards/AboutCard';
import Numbers from "./Numbers";
import Why from "./Why";

export default function About() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="about-container">
        <div className="about-content">
          <div className="about-left" data-aos="fade-left">
            <img src="./images/about/about1.jpg" alt="about us" />
          </div>
          <div className="about-right" data-aos="fade-right">
            <p>About Talent Nest Technology</p>
            <h2>We Are Increasing Business Success With <b style={{color:"rgb(0, 65, 120)"}}>Technology</b></h2>
            <p>Talent Nest technology is a dynamic and innovative IT services provider, dedicated to delivering exceptional solutions tailored to your unique business needs. With a team of passionate and skilled professionals, we strive to empower your organization’s growth by embracing cutting-edge technologies and fostering long-lasting partnerships. Trust us to be your strategic partner in unlocking your full potential and driving success in today’s digital landscape.</p>
           
          </div>
        </div>
        <div className="vision-mission">
          <AboutCard url="./images/about/vision.png" title="vision" description="Our vision is to become an industry leader in delivering state-of-the-art IT services, shaping the future of technology and contributing to a more connected, efficient, and prosperous global community." />
          <AboutCard url="./images/about/mission.png" title="mission" description="Our mission is to leverage technology as a catalyst for transformation, empowering businesses to thrive in the digital era. We achieve this by providing tailored IT solutions, fostering innovation, and nurturing long-lasting relationships with our clients." />
        </div>
      </div>
      <Numbers/>
      <Why/>
    </>
  )
}
