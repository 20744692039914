import React,{useState} from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import './Numbers.css';
export default function Numbers() {
  const [counterOn,setCounterOn] = useState(false);
  return (
    <>
      <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
        <div className="number-container">
          <div style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          }}>
            <img src='./images/numbers/1.png'/>
            <div className='counter-text'>
            <h2>
              {counterOn && <CountUp start={0} end={1000} duration={3} separator="," />}+
            </h2>
            <p>Finished Projects</p>
            </div>
          </div>
          <div style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          }}>
            <img src='./images/numbers/2.png'/>
            <div className='counter-text'>
            <h2>
              {counterOn && <CountUp start={0} end={100} duration={3} separator="," />}+
            </h2>
            <p>Happy Clients</p>
            </div>
          </div>
          <div style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          }}>
            <img src='./images/numbers/3.png'/>
            <div className='counter-text'>
            <h2>
              {counterOn && <CountUp start={0} end={1000} duration={3} separator="," />}+
            </h2>
            <p>Skilled Experts</p>
            </div>
          </div>
          <div style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          }}>
            <img src='./images/numbers/4.png'/>
            <div className='counter-text'>
            <h2>
              {counterOn && <CountUp start={0} end={100} duration={3} separator="," />}+
            </h2>
            <p>Honorable Awards</p>
            </div>
          </div>
          
       
        </div>
      </ScrollTrigger>
    </>
  )
}
