import React, { useState } from "react";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Testimonials from "./components/Testimonials";
import { BrowserRouter, NavLink,Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import ContactForm from "./components/ContactForm";
import ScrollToTop from "./components/ScrollToTop";
import OuterAbout from "./components/OuterAbout";
import OurServices from "./components/OurServices";
import WebService from "./components/services/WebService";
import PayrollService from "./components/services/PayrollService";
import HrmsService from "./components/services/HrmsService";
import OutService from "./components/services/OutService";
import Clients from "./components/Clients";
function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <div className="home-container">
   

     <Header/>
      <ScrollToTop/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<OuterAbout />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/clients" element={<Clients/>} />
        <Route path="/services" element={<OurServices/>} />
        <Route path="/web" element={<WebService/>} />
        <Route path="/payroll" element={<PayrollService/>} />
        <Route path="/hrms" element={<HrmsService/>} />
        <Route path="/outsourcing" element={<OutService/>} />
        <Route path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
