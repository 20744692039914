import React, { useState, useEffect } from "react";
import "../App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <header>
      <div class="social-media phone">
            <a href="https://api.whatsapp.com/send?phone=9910154144/" target="_blank"><img src="./images/whatsapp.png" alt=""/></a>
             <a href="https://www.instagram.com/talentnest_technology_?igsh=MWt3MGhqOTA4eGZnZg%3D%3D&utm_source=qr" target="_blank"><img src="./images/instagram.png" alt=""/></a>
             <a href="mailto:Hr@talentnesttechnology.com"><img src="./images/email.png" alt=""/></a>
             <a href="https://www.linkedin.com/company/104736805/admin/dashboard/"><img src="./images/linkedin.png" alt=""/></a>
        </div>
        <NavLink to="/" onClick={closeMenu}>
          <div className="logo">
            <img src="./images/snaplessons.png" alt="Snap Lessons Logo" />
            <div className="logo-text">

            </div>
          </div>
        </NavLink>
        <a href="tel:+91 9910154144">
          <div className="phone">
            <div className="contact-icon">
              <img src="./images/icons/phone-call.png" alt="Phone Icon" />
            </div>
            <div className="contact-info">
               Contact Now
            </div>
          </div>
        </a>
        <div className="navbar1" id="mynav">
          <div className="responsive">
            <div
              className={`menu-icon ${menuOpen ? "close" : ""}`}
              onClick={toggleMenu}
            >
              <div className="bar"></div>
              <div className="bar1"></div>
              <div className="bar"></div>
            </div>
          </div>
          <nav className={menuOpen ? "show" : ""}>
            
            <NavLink to="/about" className="show-navitems" onClick={toggleMenu}>
              About Us
            </NavLink>
            <NavLink
                to="/services"
                className="show-navitems"
                onClick={toggleMenu}>
                Our Services
              </NavLink>
         
            <NavLink to="/clients" className="show-navitems" onClick={toggleMenu}>
            Our Clients
            </NavLink>
            <NavLink to="/contact" className="show-navitems" onClick={toggleMenu}>
              Contact Us
            </NavLink>
          </nav>
        </div>
      </header>
      <div className="navbar" id="mynav">
        <div className="responsive">
          <div
            className={`menu-icon ${menuOpen ? "close" : ""}`}
            onClick={toggleMenu}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
        <nav className={menuOpen ? "show" : ""}>
          <NavLink to="/" className="show-navitems">
            Home
          </NavLink>
          <NavLink to="/about" className="show-navitems">
            About Us
          </NavLink>
          <div className="dropdown">
            <NavLink to="/services" className="show-navitems">
              Our Services
              <span id="arrow">&#9660;</span>
            </NavLink>
            <div className="dropdown-content">
                <NavLink to="/web" onClick={closeMenu}>
                  Web Development
                </NavLink>
                <NavLink to="/payroll" onClick={closeMenu}>
                  Payroll
                </NavLink>
                <NavLink to="/hrms" onClick={closeMenu}>
                  HRMS
                </NavLink>
                <NavLink to="/outsourcing" onClick={closeMenu}>
                  Out Sourcing
                </NavLink>
              </div>
          </div>
          <NavLink to="/clients" className="show-navitems">
          Our Clients
          </NavLink>
          <NavLink to="/contact" className="show-navitems">
            Contact Us
          </NavLink>
        </nav>
      </div>
    </>
  );
}
