import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './App.css';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <a 
        href="https://wa.me/+919910154144"
        class="whatsapp-button-fixed"
        target="_blank"
      >
        <img src="./images/icons/whatsapp.png" alt="WhatsApp" />
      </a>
     <App/>
    </BrowserRouter>
  </React.StrictMode>
);


