import React,{useState} from 'react'
import Slideshow from './Slideshow';
import Testimonials from './Testimonials';
import About from './About';
import '../App.js';
import WorkProcess from './WorkProcess.js';
import Learnmore from './Learnmore.js';

export default function Home() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500
  };

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
    const images = [
      "./images/slider/1.jpg",
      "./images/slider/4.jpg",
      "./images/slider/3.jpg"
    ];
    const testimonials = [
      {
        image:"./images/testimonials/man.png",
        review: "This is an amazing product!",
        desc: "It has exceeded all my expectations.",
        name: "Rahul Sharma"
      },
      {
        image:"./images/testimonials/women.png",
        review: "I had a great experience with this service.",
        desc: "The support team was very helpful.",
        name: "Priya Mehta"
      },
      {
        image:"./images/testimonials/women.png",
        review: "Highly recommend to everyone.",
        desc: "The quality and efficiency are top-notch.",
        name: "Anjali Patel"
      },
      {
        image:"./images/testimonials/man.png",
        review: "A fantastic solution.",
        desc: "The user interface easy to use.",
        name: "Vikram Gupta"
      },
      {
        image:"./images/testimonials/women.png",
        review: "Great value for money!",
        desc: "The features are outstanding.",
        name: "Neha Singh"
      }
    ];
  return (

    <>
      <Slideshow images={images} interval={3000} />
      <About/>
      <WorkProcess/>
      <Testimonials testimonials={testimonials} />
      <Learnmore/>
    </>
  )
}
