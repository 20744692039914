import React from 'react';
import '../App.css'
import AOS from 'aos'
import { useEffect } from'react'
import "aos/dist/aos.css"
import "./Footer.css"
import { NavLink } from 'react-router-dom';

export default function Footer() {
    useEffect(()=>{
        AOS.init({duration:2000})
      },[]);
  return (
    <>
      <div className='footer' >
          <br></br>
          <div className='inner-footer'>
               <div className='first'> 
                   <div className='footer-logo'>
                    <img src="./images/snaplessons.png" alt="" />
                    <h2>Talent Nest<br/>Technology</h2>
                   </div>
                    <p>Talent Nest technology is a dynamic and innovative IT services provider, dedicated to delivering exceptional solutions tailored to your unique business needs.</p>
                    <br/>
                    <p><b>Aravi Jain CEO and Director</b></p>
               </div>
               <div className='footer-company'>
                   <h4>Quick Links</h4>
                   <span><hr style={{
                    height:"4px",
                    backgroundColor:"red",
                    border:"red",
                    borderRadius:"5px",
                    marginBottom:"8px"
                   }}></hr></span>
                    <ul>
                       <li><NavLink to='/about'>About Us</NavLink></li>
                        <li><NavLink to='/services'>Our Services</NavLink></li>
                        <li><NavLink to='/contact'>Contact Us</NavLink></li>
                   </ul>

                   <br></br>  <br></br>
               </div>  
               <div className='footer-contact'>
                   <h4 >Contact Info</h4>
                   <span><hr style={{
                    height:"4px",
                    backgroundColor:"red",
                    border:"red",
                    borderRadius:"5px",
                    marginBottom:"8px"
                   }}></hr></span>
                   <ul>
                   <li><img src='./images/icons/pin.png'/>noida, Uttar Pradesh, IndiaP</li>
                   <li><img src='./images/icons/email-w.png'/>Hr@talentnesttechnology.com</li>
                   <li><img src='./images/icons/phone-call.png'/>+91 9910154144</li>
                   </ul>
                   <br></br>  <br></br>
               </div>

          </div>
          <div className='copyright'>  <center><p> © Talent Nest Technology. All rights reserved.</p></center></div>
      </div>
    </>
  )
}
 {/*      <div className='follow'>
                   <h4>FOLLOW US</h4>
                   <span><hr style={{
                    height:"2px",
                    backgroundColor:"rgb(78, 122, 252)",
                    border:"1px solid rgb(78, 122, 252)",
                    borderRadius:"5px"
                   }}></hr></span>
                   <br></br>
                  <div style={{
                    display:"flex",
                    padding:"10px"
                  }}>
                  <a href="https://www.facebook.com/profile.php?id=61557878957830&mibextid=ZbWKwL" className="fa fa-facebook"></a>  
                    <a href="https://www.instagram.com/capitalizehub?igsh=aHo5Njc5d2NscmRi" className="fa fa-instagram"></a>  
                   <a href="https://www.linkedin.com/company/capitalize-hub/" className="fa fa-linkedin"></a>
                   <a href="https://youtube.com/@StartXwithAK?si=Sf9YSwqumRX5Gkz6" className="fa fa-youtube"></a>
                  </div>
                   <br></br>  <br></br>
               </div> */}     