import React from 'react'
import ServiceCard from './cards/ServiceCard'
import './ourservices.css'
export default function OurServices() {
  return (
    <>
        <div className="title-container">
          <h1>Our Services</h1>
        
      </div>
      <div className='services'>
            <ServiceCard image="./images/services/web.png" title="Web Development" desc="Discover the digital transformation journey with our top-notch web development services. " linkto="/web"/>
            <ServiceCard image="./images/services/payroll.png" title="Payroll" desc="Efficiently manage your workforce’s financial needs with our top-notch payroll service" linkto="/payroll"/>
            <ServiceCard image="./images/services/hrms.png" title="HRMS" desc="Streamline your HR processes and enhance employee experience with our cutting-edge Human Resource Management System (HRMS) service." linkto="/hrms"/>
            <ServiceCard image="./images/services/out-sourcing.png" title="Out Sourcing" desc="Elevate your business performance by leveraging our comprehensive outsourcing services." linkto="/outsourcing"/>
      </div>
    </>
  )
}
