import React, { useEffect } from "react";
import AOS from 'aos';
import "aos/dist/aos.css";
import './Why.css';
import AboutCard from './cards/AboutCard';
import Numbers from "./Numbers";

export default function Why() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="why-container">
        <div className="why-content">
          <div className="why-left" data-aos="fade-left">
            <img src="./images/about/about.png" alt="about us" />
          </div>
          <div className="why-right" data-aos="fade-right">
            <p>WHY CHOOSE US?</p>
            <h2>More Than 24+ Years Experience We provide <b style={{color:"rgb(0,65,100)"}}>IT Services</b></h2>
            <p>We prioritize your success. Our dedicated team of experts combines their extensive knowledge and experience to deliver customized IT solutions that cater to your specific needs. We are committed to fostering long-lasting partnerships, ensuring seamless communication, and providing unparalleled customer support. By choosing us, you gain access to cutting-edge technology, innovative strategies, and a relentless focus on your growth and satisfaction. Trust us to be your reliable and forward-thinking technology partner.</p>
            
          </div>
          
        </div>
        <div className="benefits">
          <div className="awards">
            <div className="list">
              <ul>
                <li data-aos="fade-down"><span>Big Data Analysis</span></li>
                <li data-aos="fade-down"><span>24/7 Online Support</span></li>
                <li data-aos="fade-down"><span>Business Improvement</span></li>
                <li data-aos="fade-down"><span>High Quality Sicurity</span></li>
                <li data-aos="fade-down"><span>24/7 Expert Team</span></li>
                <li data-aos="fade-down"><span>Easy Solutions</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
