import React from "react";
import "./clients.css";
import Slider from "react-infinite-logo-slider";
import Slideshow from "./Slideshow";
export default function Clients() {
  const images = [
    "./images/slider/1.png",
    "./images/slider/2.png",

  ];
  return (
    <>
      <div className="clients-container">
     
         <h1>Top Hiring Companies</h1> <div className="clients-slider">
          <Slider
            width="320px"
            duration={70}
            blurBorders={false}
            blurBoderColor={"#fff"}
          >
            <Slider.Slide>
              <img
                src="./images/clients/cyber.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/exl.jpeg"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/havells.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/ioweb.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/minds.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/nexplace.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/team.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/techm.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/ms.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/oracle.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/soti.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/wipro.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/wm.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/client6.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
          </Slider>
        </div> 
      </div>
    </>
  );
}
