import React,{useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import AOS from 'aos';
import "aos/dist/aos.css";
export default function ServiceCard(props) {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>
       <div className='service-card' data-aos="fade-down">
         <img src={props.image} alt="" />
         <h2>{props.title}</h2>
         <p>{props.desc}</p> 
         <NavLink to={props.linkto}>Read More</NavLink>
       </div>
    </>
  )
}
