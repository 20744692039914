import React from "react";
import MyService from "./MyService";
import Serve from "./Serve";
import ContactCard from "../cards/ContactCard";
export default function HrmsService() {
  return (
    <>
      <div className="title-container">
        <h1>HRMS</h1>
      </div>
      <MyService title="Human Resource Management System(HRMS)" image="./images/services/hrms1.png"
      desc="Revolutionize your HR operations with our advanced HRMS service, offering a centralized platform for efficient workforce management. Key features include employee self-service portals, streamlined recruitment and onboarding, performance management tools, compliance and reporting capabilities, and analytics for data-driven HR strategies. Empower your employees with easy access to personal information and benefits management, simplify hiring with automated workflows, and foster growth through continuous feedback mechanisms. Stay compliant with the latest regulations and gain valuable insights into workforce trends. Enhance employee experience, improve productivity, and drive overall business success with our comprehensive HRMS solution. Experience the transformative power of our HRMS service and witness a new era of HR excellence."/>
      <Serve image="./images/services/hrms2.png" />
      <ContactCard/>
    </>
  );
}
