import React,{useEffect} from "react";
import { NavLink } from "react-router-dom";
import "./cards.css";
import AOS from 'aos';
import "aos/dist/aos.css";
export default function ContactCard() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>
      <p align="center">
      <div className="contact-card" data-aos="flip-left">
        <img src="./images/services/contact.png" alt="User" />
        <div className="contact-info">
         <p>
           LET’S CONNECT
          </p>
          <h2>Let’s Talk About Business Solutions <b style={{color:"rgb(0,60,120)"}}>With Us</b></h2>
         
          <NavLink to="/contact">Contact Us</NavLink>
        </div>
      </div>
      </p>
    </>
  );
}
