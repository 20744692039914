import React from "react";
import "./myservice.css";
export default function Serve(props) {
  return (
    <>
      <div className="serve-container">
          <div className="serve-item">
          <h3>We Serve The Best Work</h3>
        <div className="benefits">
          <div className="awards">
            <div className="list">
              <ul>
                <li data-aos="fade-down">
                  <span>Big Data Analysis</span>
                </li>
                <li data-aos="fade-down">
                  <span>24/7 Online Support</span>
                </li>
                <li data-aos="fade-down">
                  <span>Business Improvement</span>
                </li>
                <li data-aos="fade-down">
                  <span>High Quality Sicurity</span>
                </li>
                <li data-aos="fade-down">
                  <span>24/7 Expert Team</span>
                </li>
                <li data-aos="fade-down">
                  <span>Easy Solutions</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

          </div>
          <div className="serve-item">
             <img src={props.image} alt="Web Development" />
          </div>
      </div>
    </>
  );
}
