import React from "react";
import MyService from "./MyService";
import Serve from "./Serve";
import ContactCard from "../cards/ContactCard";

export default function WebService() {
  return (
    <>
      <div className="title-container">
        <h1>Web Development</h1>
      </div>
      <MyService
        title="Web Development"
        image="./images/services/web1.png"
        desc="Discover the digital transformation journey with our top-notch web development services, where cutting-edge technology meets industry best practices to create responsive, user-friendly, and visually stunning websites tailored to your business needs. Our collaborative approach ensures a customized web solution that reflects your brand identity and resonates with your target audience. Our dedicated team of designers, developers, and strategists work together to optimize your website for conversions, while our exceptional customer support guarantees prompt resolution of any issues or concerns. Partner with us for a seamless web development experience that boosts your brand’s online presence and exceeds your expectations."
      />
      <Serve image="./images/services/web2.png" />
      <ContactCard/>
    </>
  );
}
