import React,{useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import AOS from 'aos'
import "aos/dist/aos.css"


export default function Learnmore() {
  useEffect(()=>{
    AOS.init({duration:2000})
 },[]);
  return (
    <div  className='animation' data-aos="flip-right">
      <div className='box3'>
          <div className='about'>
             <p>LEARN MORE</p>
             <h2>About Talent Nest</h2>
             <NavLink to='/about'>
             <div className='arrow'>
                <i className="material-icons">arrow_forward</i>
             </div>
             </NavLink>
          </div>
          <div className='contact'>
             <p>CONTACT US</p>
                <h2>Let's Work Together</h2>
                <NavLink to='/contact'>
             <div className='arrow'>
                <i className="material-icons">arrow_forward</i>
             </div>
             </NavLink>
          </div>
      </div>
    </div>
  )
}
