import React from 'react';
import "./myservice.css";

export default function MyService(props) {
  return (
    <>
      
      <div className="my-service-container">
      <img src={props.image} alt="" />
          <div className='my-service-info'>
              <h1>{props.title}</h1>
              <br/>
              <p>{props.desc}</p>
            
          </div>
          
      </div>
    
    </>
  )
}
