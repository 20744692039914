import React from "react";
import MyService from "./MyService";
import Serve from "./Serve";
import ContactCard from "../cards/ContactCard";
export default function PayrollService() {
  return (
    <>
      <div className="title-container">
        <h1>Payroll</h1>
      </div>
      <MyService title="Payroll Service" image="./images/services/payroll1.png" 
      desc="Streamline your business with our comprehensive payroll service, designed to efficiently manage your workforce’s financial needs. Our user-friendly platform simplifies payroll processing, ensuring timely and accurate employee payments. Key features include seamless integration with your HR system, customizable reports, compliance with tax and regulatory requirements, secure data storage, and dedicated support from our experienced team. Experience the benefits of our payroll service today and focus on your core business operations while we handle your employees’ financial well-being."/>
      <Serve image="./images/services/payroll2.png" />
      <ContactCard />
    </>

  );
}
