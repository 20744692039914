import React from 'react'
import './workprocess.css'
import WorkCard from './cards/WorkCard'
export default function WorkProcess() {
  return (
    <>
      <div className="work-process">
        <h2>Our Work Process</h2>
        <h3>How Talent Nest Technology Work It!</h3>
        <div className="work-process-cards">
            <WorkCard number="1" image="./images/icons/1.png" title="Select a project" desc="We will first a select a project form the client."/>
            <WorkCard number="2" image="./images/icons/2.png" title="Project analysis" desc="We will first a select a project form the client."/>
            <WorkCard number="3" image="./images/icons/3.png" title="Plan Execute" desc="We will first a select a project form the client."/>
            <WorkCard number="4" image="./images/icons/4.png" title="Deliver result" desc="We will first a select a project form the client."/>
        </div>
      </div>
    </>
  )
}
