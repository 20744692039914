import React, { useEffect } from "react";
import AOS from 'aos';
import "aos/dist/aos.css";
import './about.css';
import AboutCard from './cards/AboutCard';
import Numbers from "./Numbers";
import Why from "./Why";
import ContactCard from "./cards/ContactCard";

export default function OuterAbout() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="title-container">
          <h1>About Us</h1>
      </div>
      <div className="about-container">
        <div className="about-content">
          <div className="about-left" data-aos="fade-left">
            <img src="./images/about/about1.jpg" alt="about us" />
          </div>
          <div className="about-right" data-aos="fade-right">
            <p>About Talent Nest Technology</p>
            <h2>We Are Increasing Business Success With <b style={{color:"rgb(0, 65, 120)"}}>Technology</b></h2>
            <p>Talent Nest is a dynamic and forward-thinking IT services provider, dedicated to delivering top-notch solutions tailored to your specific business needs. Our team of passionate and skilled professionals is committed to fueling your organization’s growth through the adoption of cutting-edge technologies.</p>
            <p>We focus on cultivating enduring partnerships to ensure that our solutions drive meaningful results. Rely on us as your strategic ally to unlock your full potential and achieve success in today’s ever-evolving digital landscape..</p>
          </div>
        </div>
        <div className="vision-mission">
          <AboutCard url="./images/about/vision.png" title="vision" description="Our vision is to become an industry leader in delivering state-of-the-art IT services, shaping the future of technology and contributing to a more connected, efficient, and prosperous global community." />
          <AboutCard url="./images/about/mission.png" title="mission" description="Our mission is to leverage technology as a catalyst for transformation, empowering businesses to thrive in the digital era. We achieve this by providing tailored IT solutions, fostering innovation, and nurturing long-lasting relationships with our clients." />
        </div>
      </div>
      <Numbers/>
      <Why/>
      <ContactCard/>
    </>
  )
}
